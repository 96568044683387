<template>
  <b-card
    class="h-100 p-2 mx-1 d-flex justify-content-center align-items-center"
  >
    <b-card-text class="h1 font-medium-3 text-center text-primary">
      {{ guidelineItem.label }}
    </b-card-text>

    <table class="table table-sm table-borderless">
      <thead>
        <tr>
          <th
            scope="col"
            v-for="(header, index) in guidelineItem.headers"
            :key="index"
          >
            {{ header }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in guidelineItem.items"
          :key="index"
          class="px-1"
        >
          <td>{{ item[0] }}</td>
          <td>{{ item[1] }}</td>
        </tr>
      </tbody>
    </table>
  </b-card>
</template>

<script>
export default {
  props: {
    guidelineItem: Object,
  },
};
</script>

<style></style>
